import { call, select, takeLatest } from 'redux-saga/effects';

import logger from '@frello-tech/front-utils/dist/utils/logger';
import { SEND_RESPONSE_FOR_AUTO_CORRECTION } from '../actions';
import { sendResponseForAutoCorrection } from '../services/api/autoCorrection';
import { getAuthData } from '../selectors';
import { loadSurvey } from './modules';

function * flow () {
  yield takeLatest(SEND_RESPONSE_FOR_AUTO_CORRECTION, sendResponseForAutoCorrectionSaga);
}

function * sendResponseForAutoCorrectionSaga ({ payload }) {
  try {
    const authData = yield select(getAuthData);

    yield call(sendResponseForAutoCorrection, { authData, params: payload });

    yield call(loadSurvey, { payload: { moduleId: payload.moduleId } });
  } catch (error) {
    yield call(console.log, 'sendResponseForAutoCorrectionSaga error');
    logger.error(error);
  }
}

export default flow;
