import { fork } from 'redux-saga/effects';
import auth from './auth';
import modules from './modules';
import navigate from './navigate';
import audio from './audio';
import ui from './ui';
import cmi5 from './CMI5';
import cmi5Maskoot from './CMI5Maskott';
import frello from './frello';
import lti from './lti';
import user from './user';
import autoCorrection from './autoCorrection';

function * rootSaga () {
  yield fork(auth);
  yield fork(modules);
  yield fork(navigate);
  yield fork(audio);
  yield fork(ui);
  yield fork(cmi5);
  yield fork(lti);
  yield fork(cmi5Maskoot);
  yield fork(frello);
  yield fork(user);
  yield fork(autoCorrection);
}

export default rootSaga;
