import { put, select, takeEvery } from 'redux-saga/effects';
import { getCurrentUserFromMS } from '../services/api/user';
import * as Sentry from '@sentry/browser';
import { isMixPanelLoaded, mixpanel, analyticsTrack } from '../services/analytics';
import logger from '@frello-tech/front-utils/dist/utils/logger';
import { LEARNER_BUG_REPORT, USER_LOGIN_SUCCESS } from '../actions/index';

function * flow () {
  yield getCurrentUser();
  yield takeEvery(LEARNER_BUG_REPORT, learnerBugReport);
}

function * getCurrentUser () {
  try {
    const authData = yield select(state => ({
      accessToken: state.user.accessToken,
      userAgent: state.user.agent
    }));
    const user = yield getCurrentUserFromMS({ authData });
    if (user) {
      console.log('user', user);
      yield put({ type: USER_LOGIN_SUCCESS, payload: user });
      isMixPanelLoaded && mixpanel.identify(user.id);
      isMixPanelLoaded && mixpanel.set({
        $first_name: user?.firstName,
        $last_name: user?.lastName,
        $email: user?.email,
        $created: user?.inscriptionDate,
        $type: user?.type
      });

      Sentry.setUser({
        id: user?.id,
        roles: user?.roles
      });
    }
  } catch (e) {
    logger.error('getCurrentUser error', e);
  }
}

function * learnerBugReport ({ payload }) {
  try {
    analyticsTrack('A signalé un bug', payload.data);
    logger.log('learnerReportSuccess', { extra: payload.data });
  } catch (error) {
    logger.error('learnerReportFailure', error);
  }
}

export default flow;
