import { MIXPANEL_TOKEN } from '../../js/constants/api.js';
import mixpanel from 'mixpanel-browser';

const mixPanelToken = MIXPANEL_TOKEN;

if (mixPanelToken) {
  mixpanel.init(mixPanelToken, { debug: false, ip: false, ignore_dnt: true });
}

// eslint-disable-next-line no-underscore-dangle
// @ts-expect-error config shoud exist
const isMixPanelInit = typeof mixpanel !== 'undefined' && mixpanel.config && mixpanel.track;
export const isMixPanelLoaded = isMixPanelInit !== undefined;

const defaultProperty = {
  project: 'module-viewer-web-app'
  // env: process.env.NODE_ENV
};

export const analyticsTrack = (eventName, properties = {}) => {
  const mergedProperties = { ...defaultProperty, ...properties };

  mixpanel.track(eventName, mergedProperties);
};

export default mixpanel;
