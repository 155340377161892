import axios from 'axios';
import { CORRECTIONS_MS_URL } from '../../constants/api.js';
import logger from '@frello-tech/front-utils/dist/utils/logger.js';
import { getAccessToken } from './auth.js';

export const sendResponseForAutoCorrection = async ({ authData, params }) => {
  const url = CORRECTIONS_MS_URL;

  const data = JSON.stringify({
    query: `mutation autoCorrectResponse(
    $moduleId: ID
    $responseId: ID 
    $question: String
    $response: String
    $audio: String
    $point: Int
    $level: String
    ) { 
        autoCorrectResponse(
        moduleId: $moduleId
        responseId: $responseId
        question: $question
        response: $response
        audio: $audio
        point: $point
        level: $level
        )
      }`,
    variables: params
  });

  const axiosConfig = {
    method: 'post',
    url,
    headers: {
      Authorization: `Bearer ${await getAccessToken(authData)}`,
      'Content-Type': 'application/json'
    },
    data
  };

  try {
    await axios(axiosConfig);
  } catch (error) {
    logger.error(error);
  }
};

export const sendCorrectionRating = async ({ authData, ...params }) => {
  const url = CORRECTIONS_MS_URL;

  const data = JSON.stringify({
    query: `mutation correctionRating(
    $question: String
    $aiResponse: String
    $comment: String
    $learnerRating: String
    ) { 
        correctionRating(
        aiResponse: $aiResponse
        comment: $comment
        question: $question
        learnerRating: $learnerRating
        )
      }`,
    variables: params
  });

  const axiosConfig = {
    method: 'post',
    url,
    headers: {
      Authorization: `Bearer ${await getAccessToken(authData)}`,
      'Content-Type': 'application/json'
    },
    data
  };

  try {
    await axios(axiosConfig);
  } catch (error) {
    logger.error(error);
  }
};
